<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'imofalform6vehicles'">
					<v-row class="pui-form-layout">
						<v-col cols="12">
							<v-row dense>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('imofalform6vehicles.fieldset.passenger')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`imofalform6vehicles-passengerid`"
													attach="imofalform6vehicles-passengerid"
													:label="this.labelpassenger"
													toplabel
													v-model="model"
													modelName="vlupimofalform6vehicles"
													reactive
													:disabled="formDisabled"
													:modelFormMapping="{ imofalform6id: 'passengerid' }"
													:itemsToSelect="itemsToSelectPassenger"
													:itemValue="['imofalform6id']"
													:itemText="(item) => `${item.familyname}, ${item.givenname} - ${item.documentnumber}`"
													:order="{ givenname: 'asc' }"
													:fixedFilter="filterPassenger"
												></pui-select>
											</v-col>

											<!-- LICENCECATEGORY -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`LICENCECATEGORY-imofalform6vehicles`"
													v-model="model.licencecategory"
													:label="$t('imofalform6vehicles.licencecategory')"
													:disabled="formDisabled"
													toplabel
													required
													maxlength="3"
												></pui-text-field>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('imofalform6vehicles.fieldset.vehicle')">
										<v-row dense>
											<!-- PLATENUMBER1 -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`PLATENUMBER-imofalform6vehicles`"
													v-model="model.plate"
													:label="$t('imofalform6vehicles.plate')"
													:disabled="formDisabled"
													toplabel
													required
													maxlength="10"
												></pui-text-field>
											</v-col>
											<!--VIN -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`VIN-imofalform6vehicles`"
													v-model="model.vin"
													:label="$t('imofalform6vehicles.vin')"
													:disabled="formDisabled"
													toplabel
													required
													maxlength="100"
												></pui-text-field>
											</v-col>
											<!-- VEHICLEBRAND -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`VEHICLEBRAND-imofalform6vehicles`"
													v-model="model.brand"
													:label="$t('imofalform6vehicles.vehiclebrand')"
													:disabled="formDisabled"
													toplabel
													required
													maxlength="100"
												></pui-text-field>
											</v-col>
											<!-- VEHICLECATEGORY -->
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`VEHICLECATEGORY-imofalform6vehicles`"
													:label="$t('imofalform6vehicles.vehiclecategory')"
													toplabel
													required
													:disabled="formDisabled"
													singleItemAutoselect
													v-model="model"
													:modelFormMapping="{ value: 'category' }"
													:itemsToSelect="vehiclecategoryToSelect"
													itemValue="value"
													itemText="text"
													:items="codes2"
												></pui-select>
											</v-col>

											<!-- CARGO -->
											<v-col v-if="this.model.category === 'M3'" class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
												<pui-text-field
													:id="`CARGO-imofalform6vehicles`"
													v-model="model.cargo"
													:label="$t('imofalform6vehicles.cargo')"
													:disabled="formDisabled"
													toplabel
													required
													maxlength="100"
												></pui-text-field>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
							<!-- MINI AUDIT -->
						</v-col>
					</v-row>
				</v-tab-item>
				<!-- Page Footer -->
				<pui-form-footer>
					<pui-form-footer-btns
						:formDisabled="!havePermissions(['WRITE_IMOFALFORM6'])"
						:saveDisabled="saving"
						:saveAndNew="saveAndNew"
						:saveAndUpdate="saveAndUpdate"
						:save="save"
						:back="back"
					></pui-form-footer-btns>
				</pui-form-footer>
			</v-tabs-items>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'imofalform6vehicles-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'imofalform6vehicles',
			parentModelName: 'imofalform6menu',
			modeldetail: true,
			tabmodel: 'imofalform6vehicles',
			labelpassenger: this.$t('imofalform6vehicles.passenger'),
			birthdate: null,

			codes: [
				{
					text: 'Yes',
					value: '1'
				},
				{
					text: 'No',
					value: '0'
				}
			],
			codes2: [
				{
					text: 'M1',
					value: 'M1'
				},
				{
					text: 'M2',
					value: 'M2'
				},
				{
					text: 'M3',
					value: 'M3'
				}
			]
		};
	},
	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		afterGetData() {
			// Do something after get data from server

			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);

			if (parentModel) {
				this.model.imofalformid = parentModel.pk.imofalformid;
			}

			if (this.$route.params.parentpk) {
				this.model.imofalformid = JSON.parse(atob(this.$route.params.pkgeneral)).imofalformid;
			}
		}
	},
	computed: {
		filterPassenger() {
			return {
				groups: [
					{
						groupOp: 'or',
						rules: [{ field: 'imofalformid', op: 'eq', data: this.model.imofalformid }]
					}
				]
			};
		},

		vehiclecategoryToSelect() {
			return [{ value: this.model.category }];
		},
		itemsToSelectPassenger() {
			return [{ imofalform6id: this.model.passengerid }];
		}
	},
	watch: {
		'model.vehicleind'() {
			if (this.model.vehicleind === null) {
				this.model.vehicleind = '0';
			}
		}
	}
};
</script>
